import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { CreatePortfolio, Portfolio } from '@netpurpose/api'
import {
  AutoCompleteWithNew,
  Button,
  DatePicker,
  ErrorMessage,
  Icon,
  Input,
  NumberInput,
  PortfolioBadge,
} from '@netpurpose/np-ui'
import {
  BottomContainer,
  FieldLabelContainer,
  FileUploadedContainer,
  GridContainer,
  LabelText,
  ModalBodyContainer,
  RadioButton,
  RadioContainer,
  RadioContent,
  RadioGroup,
} from './CreatePortfolioAction.style'
import { UploadType } from './CreatePortfolioContent'

const getPortfolioNameOptions = (portfolios: Portfolio[], uploadType: UploadType) => {
  const options = portfolios
    .filter((p) => p.type !== 'watchlist')
    .filter((p) =>
      uploadType === 'direct' ? p.type !== 'fund of funds' : p.type === 'fund of funds',
    )
    .map((p) => ({
      label: p.name,
      value: p.name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value))

  return Array.from(new Map(options.map((p) => [p.value, p])).values())
}

const isValidType = (type: Portfolio['type'] | undefined) =>
  !!type && type !== 'watchlist' && type !== 'fund of funds'

export const CreatePortfolioForm = ({
  handleUpload,
  setStep,
  visible,
  fileName,
  isPending,
  errorMessage,
  setErrorMessage,
  uploadType,
  portfolios,
}: {
  handleUpload: (formValues: {
    name: string
    type: CreatePortfolio['type']
    totalValue: number
    valuationDate: Date
  }) => void
  setStep: Dispatch<SetStateAction<1 | 2>>
  visible: boolean
  fileName: string | undefined
  isPending: boolean
  errorMessage: string | null
  setErrorMessage: Dispatch<SetStateAction<string | null>>
  uploadType: UploadType
  portfolios: Portfolio[]
}) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState<string | null>(null)

  const selectedPortfolioType = selectedPortfolio
    ? portfolios.find((p) => p.name === selectedPortfolio)?.type
    : undefined

  useEffect(() => {
    setSelectedPortfolio(null)
  }, [uploadType])

  const portfolioNameOptions = useMemo(
    () => getPortfolioNameOptions(portfolios, uploadType),
    [portfolios, uploadType],
  )
  const portfolioNames = portfolioNameOptions.map((p) => p.value)

  const onSubmit = (formValues: {
    name: string
    type: CreatePortfolio['type']
    totalValue: number
    valuationDate: Date
  }) => {
    handleUpload({
      ...formValues,
      type: isValidType(selectedPortfolioType) ? selectedPortfolioType : formValues.type,
    })
  }

  return (
    <Formik
      initialValues={{
        name: '',
        valuationDate: new Date(),
        // Casting to unknown because of a type mismatch between initial values and submitted values
        // totalValue and type cannot be undefined but we want them to be empty form fields initially
        totalValue: undefined as unknown as number,
        type: 'private',
      }}
      validationSchema={yup.object({
        name: yup.string().required('Required'),
        type: yup.string().required('Required'),
        totalValue: yup.number().required('Required'),
        valuationDate: yup.date().required('Required'),
      })}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <ModalBodyContainer $visible={visible} $morePadding>
            <FieldLabelContainer>
              <Form.Item name="name">
                <LabelText>Name</LabelText>
                <AutoCompleteWithNew
                  name="name"
                  aria-label="Portfolio name"
                  placeholder="Create new portfolio or select an existing one to update"
                  showSearch
                  optionFilterProp="label"
                  options={portfolioNameOptions}
                  onSelect={setSelectedPortfolio}
                  onChange={(input) =>
                    portfolioNames.includes(input)
                      ? setSelectedPortfolio(input)
                      : setSelectedPortfolio(null)
                  }
                  data-testid="portfolio-name-input"
                  allowClear
                />
              </Form.Item>
            </FieldLabelContainer>
            <FieldLabelContainer>
              <LabelText>File Uploaded</LabelText>
              <FileUploadedContainer>
                <Icon icon="Excel" alt="Excel icon" viewbox="0 0 33 32" width={24} height={24} />
                <span>{fileName}</span>
              </FileUploadedContainer>
            </FieldLabelContainer>
            <FieldLabelContainer>
              <Form.Item name="type">
                <LabelText>Type</LabelText>
                <RadioGroup defaultValue="private">
                  {(!selectedPortfolioType ||
                    ['private', 'fund of funds'].includes(selectedPortfolioType)) && (
                    <RadioButton
                      value="private"
                      onClick={() => setFieldValue('type', 'private')}
                      checked={selectedPortfolioType === 'private'}
                    >
                      <RadioContainer className="radio-container">
                        <PortfolioBadge type="private" />
                        <RadioContent>
                          <span>Portfolio</span>
                          <span>A portfolio held by yourself or your firm.</span>
                        </RadioContent>
                      </RadioContainer>
                    </RadioButton>
                  )}
                  {((uploadType === 'direct' && !selectedPortfolioType) ||
                    selectedPortfolioType === 'benchmark') && (
                    <RadioButton
                      value="benchmark"
                      onClick={() => setFieldValue('type', 'benchmark')}
                      checked={selectedPortfolioType === 'benchmark'}
                    >
                      <RadioContainer className="radio-container">
                        <PortfolioBadge type="benchmark" />
                        <RadioContent>
                          <span>Benchmark</span>
                          <span>A portfolio used for comparison.</span>
                        </RadioContent>
                      </RadioContainer>
                    </RadioButton>
                  )}
                  {uploadType === 'direct' && selectedPortfolioType === 'fund' && (
                    <RadioButton
                      value="fund"
                      onClick={() => setFieldValue('type', 'fund')}
                      checked={selectedPortfolioType === 'fund'}
                    >
                      <RadioContainer className="radio-container">
                        <PortfolioBadge type="fund" />
                        <RadioContent>
                          <span>Fund</span>
                          <span>A fund portfolio.</span>
                        </RadioContent>
                      </RadioContainer>
                    </RadioButton>
                  )}
                </RadioGroup>
              </Form.Item>
            </FieldLabelContainer>
            <GridContainer>
              <LabelText>Currency</LabelText>
              <LabelText>Assets under management (AUM)</LabelText>
              <LabelText>Valuation date</LabelText>
              <div>
                <Input name="currency" aria-label="Currency" value={'$ (USD)'} disabled />
              </div>
              <Form.Item name="totalValue">
                <NumberInput
                  name="totalValue"
                  aria-label="Assets under management"
                  placeholder="1,000,000"
                  type="number"
                  controls={false}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="valuationDate">
                <DatePicker
                  name="valuationDate"
                  aria-label="Valuation date"
                  disabledDate={(current: Date) => {
                    return current > new Date()
                  }}
                  allowClear={false}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </GridContainer>
            {errorMessage && (
              <ErrorMessage error={`Portfolio could not be created: ${errorMessage}`} />
            )}
            <BottomContainer>
              <Button
                type="primary"
                onClick={() => {
                  setStep(1)
                  setErrorMessage(null)
                }}
              >
                Go back
              </Button>
              <Button
                level="tertiarySubmit"
                loading={isPending}
                disabled={isPending}
                data-testid="create-portfolio-form-submit-button"
              >
                Create portfolio
              </Button>
            </BottomContainer>
          </ModalBodyContainer>
        </Form>
      )}
    </Formik>
  )
}
